<template>
  <div
    v-loading="loading"
    class="up_image_model"
  >
    <el-button
      v-if="!disabled"
      type="primary"
      size="small"
      :disabled="showImage.length >= num"
      @click="onUpImage"
      style="margin-bottom: 10px;"
    >
      点击上传
    </el-button>
    <div class="up_image_box">
      <draggable
        v-model="showImage"
        class="drag-box"
        element="div"
        :force-fallback="true"
        :disabled="disabled"
        :animation="100"
        @sort="onChangeSort"
      >
        <div
          v-for="(item,i) in showImage"
          :key="i"
          class="up_image_line"
        >
          <div style="max-width: 100%">
            {{ getFileName(decodeURI(item)) }}
          </div>
          <div
            v-if="!disabled"
            class="el-icon-delete"
            style="margin-left: 5px; cursor: pointer;"
            @click="clearImage(i)"
          >
          </div>
          <el-button v-if="download" type="text" style="padding: 0; margin-left: 10px;" @click="downloadFile(item)">下载</el-button>
        </div>
      </draggable>
    </div>
    <!-- 图片上传辅助 -->
    <input
      ref="upload"
      style="display: none;"
      type="file"
      :multiple="num > 1"
      accept=".ofd, application/pdf"
      @change="changeImage($event)"
    />
  </div>
</template>

<script>
import Emitter from 'element-ui/src/mixins/emitter';
import draggable from 'vuedraggable';
import path from 'path'
import dayjs from 'dayjs'
import { upload } from '@/utils/oss'
export default {
  name: '',
  components: {
    draggable,
  },
  mixins: [Emitter],
  props: {
    files: {
      type: [Array, String],
      default: () => {
        return [];
      },
    },
    download: {
      type: Boolean,
      default: true
    },
    downloadFileName: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'array'
    },
    num: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  inject: {
    elForm: {
      default: ''
    },
    elFormItem: {
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      showImage: [],
    };
  },
  watch: {
    files: {
      handler(val) {
        if (this.type === 'string') {
          if (val !== '' && val) {
            if (val.split) {
              this.showImage = val.split(',');
            }
          }
        } else {
          this.showImage = val;
        }
      },
      immediate: true,
    },
  },
  methods: {
    getFileName(pathUrl) {
      return path.basename(pathUrl)
    },
    downloadFile(url) {
      const match = /[\/\\]([^\/\\]*)\.([^\/\\]*)$/.exec(decodeURI(url))
      if (!match) {
        return this.$message.error('无法解析文件，请确认文件地址是否正确！')
      }
      const fileName = match[1]
      const fileType = match[2]
      fetch(url).then(res => res.blob()).then(blob => {
        const a = document.createElement('a')
        const fileUrl = URL.createObjectURL(blob)
        a.href = fileUrl
        a.download = `${this.downloadFileName ? `${fileName}_${this.downloadFileName}` : fileName}_${dayjs(new Date()).format('YYYYMMDD_HHmmss')}.${fileType}`  // 下载文件的名字
        document.body.appendChild(a)
        a.click()
        URL.revokeObjectURL(fileUrl)
        document.body.removeChild(a)
      })
    },
    onChangeSort() {
      let data = this.type === 'array' ? this.showImage : this.showImage.join()
      this.$emit('update:files', data);
      this.dispatch('ElFormItem', 'el.form.change', data);
      this.$emit('change', data);
    },
    // 删除
    clearImage(i) {
      this.$confirm('确定删除该文件吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.showImage.splice(i, 1);
        let data = this.type === 'array' ? this.showImage : this.showImage.join()
        this.$emit('update:files', data);
        this.dispatch('ElFormItem', 'el.form.change', data);
        this.$emit('change', data);
      }).catch(() => {
      });
    },
    // 上传图片
    async changeImage(e) {
      if (!e.target.files[0]) return;
      for (let i = 0; i < e.target.files.length; i++) {
        let apiUrl = await this.multipartUpload(e.target.files[i]);
        if (this.showImage.length >= this.num) {
          this.$message.error(`最多只能上传${this.num}个文件`);
          return;
        }
        this.showImage.push(apiUrl);
        let data = this.type === 'array' ? this.showImage : this.showImage.join()
        this.$emit('update:files', data);
        this.dispatch('ElFormItem', 'el.form.change', data);
        this.$emit('change', data);
      }
    },
    onUpImage() {
      this.$refs.upload.value = '';
      this.$refs.upload.click();
    },
    // 定义上传方法
    multipartUpload(file) {
      return new Promise(async (resolve) => {
        const match = /([^\/\\]*)\.([^\/\\]*)$/.exec(file.name)
        const fileName = match[1]
        this.loading = true;
        upload(file, fileName).then((res)=>{
          const url = res.url.split('?')[0];
          resolve(url);
        }).finally(()=>{
          this.loading = false;
        })
      });
    },
  },
};
</script>

<style lang="scss">
.up_image {
  .el-image__preview {
    height: auto;
    border: 1px solid #ccc;
  }
  min-height: 60px;
  min-width: 200px;
}
</style>
<style scoped lang="scss">
.up_image_model {
  .up_image_box {
    display: flex;
    flex-wrap: wrap;
    .up_image_line {
      position: relative;
      display: flex;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 10px;
      width: 100%;
      .clear_icon {
        cursor: pointer;
        margin-left: 10px;
        color: #fff;
        width: 20px;
        height: 20px;
        border-radius: 0 0 0 10px;
        background: rgba(0, 0, 0, 0.3);
        line-height: 20px;
        text-align: center;
      }
    }
  }
}


.image-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drag-box{
  display: flex;
  flex-wrap: wrap;
}
</style>
