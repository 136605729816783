var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "操作日志",
        visible: _vm.visible,
        modal: "",
        center: "",
        "destroy-on-close": "",
        width: "800",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$emit("update:visible", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:visible", false)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        { staticClass: "build-billInvoicing-container" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _setup.state.loading,
                  expression: "state.loading",
                },
              ],
              attrs: {
                "element-loading-text": "拼命加载中",
                data: _setup.state.tableData,
                border: "",
                "header-row-style": { "font-weight": 100, color: "#0D0202" },
                "row-style": { "font-size": "14px", color: "#0D0202" },
                "header-cell-style": {
                  "font-weight": "bold",
                  background: "#F0F2F8",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "操作类型", prop: "type" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_setup.getTypeText(row.type)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作员", prop: "operateName" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createTime", label: "时间" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _setup
                                .dayjs(Number(row.createTime))
                                .format("YYYY-MM-DD HH:mm:ss")
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }