<template>
  <el-dialog
    title="操作日志"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    modal
    center
    destroy-on-close
    width="800"
  >
    <div class="build-billInvoicing-container">
      <el-table
        v-loading="state.loading"
        element-loading-text="拼命加载中"
        :data="state.tableData"
        border
        :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
        :row-style="{'font-size': '14px', 'color': '#0D0202'}"
        :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
      >
        <el-table-column
          label="操作类型"
          prop="type"
        >
          <template #default="{ row }">
            {{ getTypeText(row.type) }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作员"
          prop="operateName"
        />
        <el-table-column
          prop="createTime"
          label="时间"
        >
          <template #default="{ row }">
            {{ dayjs(Number(row.createTime)).format('YYYY-MM-DD HH:mm:ss') }}
          </template>
        </el-table-column>
      </el-table>
    </div>

    <template #footer>
      <el-button @click="$emit('update:visible', false)">取消</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
  import { reactive, watch, watchEffect } from 'vue'
  import scm from '@/http/nb/scm'
  import http from '@/http/axios'
  import dayjs from 'dayjs'
  import {billingInvoiceType} from './constant'
  const props = defineProps({
    visible: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    }
  })

  defineEmits(['update:visible'])

  const state = reactive({
    loading: false,
    tableData: [],
  })

  function getTypeText(data) {
    return billingInvoiceType[data]
  }

  function getList() {
    state.loading = true
    http.get(scm.billInvoicing.record, {
      params: {
        supplyBillingInvoiceId: props.id
      }
    }).then((res)=>{
      if (res?.code === 0) {
        state.tableData = res.data
      }
    }).finally(()=>{
      state.loading = false
    })
  }

  watch(()=> props.visible, ()=>{
    getList()
  })

  watchEffect(()=>{
    if (props.id) {
      getList()
    }
  })
</script>

<style lang="scss" scoped>
  .build-billInvoicing-container {
    background-color: #fff;

    .operation-records-button {
      padding: 0;
    }
    .build-billInvoicing-tip {
      margin-left: 68px;
    }

    .build-billInvoicing-header {
      border-radius: 8px;
      border: 1px solid #eee;
    }
    .build-billInvoicing-input-container {
      width: 300px;
      display: inline-block;
    }
    .build-billInvoicing-text {
      &.large {
        font-size: 16px;
        font-weight: bold;
      }
      &.red {
        color: #f00;
      }
      line-height: 24px;
    }
    :deep {
      .el-row {
        margin-bottom: 20px;
      }
      .el-collapse-item__header {
        height: auto;
        line-height: 24px;
        border: none;
      }
      .el-collapse-item__wrap {
        border: none;
      }
      .el-collapse {
        border: none;
      }
    }
  }
</style>