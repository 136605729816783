<template>
  <div>
    <el-dialog
      title="开票详情"
      :visible="visible"
      @update:visible="$emit('update:visible', $event)"
      width="800px"
      center
    >
      <div class="build-billInvoicing-container">
        <el-row>
          <el-col :span="10">
            <div class="build-billInvoicing-text large">{{ titleText }}</div>
          </el-col>
        </el-row>
        <el-row v-if="state.invoicingInfo.auditStatus === 5">
          <el-col :span="2">
            <div class="build-billInvoicing-text">驳回原因</div>
          </el-col>
          <el-col :offset="1" :span="21">
            <div class="build-billInvoicing-text">{{ state.invoicingInfo.reason }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <div class="build-billInvoicing-text">开票单据号</div>
          </el-col>
          <el-col :offset="1" :span="7">
            <div class="build-billInvoicing-text">{{ state.invoicingInfo.billingInvoiceNo }}</div>
          </el-col>
          <el-col :span="2">
            <div class="build-billInvoicing-text">创建时间</div>
          </el-col>
          <el-col :offset="1" :span="5">
            <div class="build-billInvoicing-text">{{ dayjs(Number(state.invoicingInfo.createTime)).format('YYYY-MM-DD HH:mm:ss') }}</div>
          </el-col>
          <el-col :span="2" :offset="3">
            <el-button class="operation-records-button" type="text" @click="state.invoicingDialog.logVisible = true">操作记录</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <div class="build-billInvoicing-text large">发票信息</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2">
            <div class="build-billInvoicing-text">发票类型</div>
          </el-col>
          <el-col :offset="1" :span="11">
            <div class="build-billInvoicing-text">{{ state.invoicingInfo.invoiceType === 1 ?  '电子发票' : '纸质发票' }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2">
            <div class="build-billInvoicing-text">发票种类</div>
          </el-col>
          <el-col :offset="1" :span="11">
            <div class="build-billInvoicing-text">{{ state.invoicingInfo.invoiceSpecies === 1 ?  '增值税专用发票' : '增值税普通发票' }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2">
            <div class="build-billInvoicing-text">上传发票</div>
          </el-col>
          <el-col :offset="1" :span="21">
            <upload disabled :files.sync="state.invoicingInfo.invoiceFileUrl" :downloadFileName="state.invoicingInfo.billingInvoiceNo" :num="5" type="string"  />
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2">
            <div class="build-billInvoicing-text">发票税率</div>
          </el-col>
          <el-col :offset="1" :span="11">
            <div class="build-billInvoicing-text">{{ state.invoicingInfo.invoiceRate ? `${state.invoicingInfo.invoiceRate}%` : '' }}</div>
          </el-col>
        </el-row>
        <el-row v-if="state.invoicingInfo.invoiceType === 2">
          <el-col :span="10">
            <div class="build-billInvoicing-text large">邮寄信息</div>
          </el-col>
        </el-row>
        <el-row v-if="state.invoicingInfo.invoiceType === 2">
          <el-col :span="2">
            <div class="build-billInvoicing-text">收件信息</div>
          </el-col>
          <el-col :offset="1" :span="20">
            <div class="build-billInvoicing-text">
              <div>
                <div>{{ address?.[1] }}
                  <span class="red">{{ address?.[2] }}</span>
                </div>
                <div>{{ address?.[3] }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="state.invoicingInfo.invoiceType === 2">
          <el-col :span="2">
            <div class="build-billInvoicing-text">物流单号</div>
          </el-col>
          <el-col :offset="1" :span="11">
            <div class="build-billInvoicing-text">{{ state.invoicingInfo.logisticNo }}</div>
          </el-col>
        </el-row>
        <el-row v-if="state.invoicingInfo.invoiceType === 2">
          <el-col :span="2">
            <div class="build-billInvoicing-text">物流公司</div>
          </el-col>
          <el-col :offset="1" :span="10">
            <div class="build-billInvoicing-text">{{ state.invoicingInfo.logisticName }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="3">
            <div class="build-billInvoicing-text large">账单信息</div>
          </el-col>
          <el-col  :span="12">
            <div class="build-billInvoicing-text">
              <i class="el-icon-warning"></i>
              <span>请将账单月份打印在发票备注信息部分，便于平台审核</span>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2">
            <div class="build-billInvoicing-text">账单月份</div>
          </el-col>
          <el-col :offset="1" :span="4">
            <el-collapse v-model="state.invoicingDialog.collapseActive1">
              <el-collapse-item :title="`共${state.invoicingInfo.billingMonth.length}个月`"  name="1">
                <div v-for="item of state.invoicingInfo.billingMonth">{{ item }}</div>
              </el-collapse-item>
            </el-collapse>
          </el-col>
          <el-col :span="2" :offset="9">
            <div class="build-billInvoicing-text">开票金额</div>
          </el-col>
          <el-col :offset="1" :span="4">
            <el-collapse v-model="state.invoicingDialog.collapseActive2">
              <el-collapse-item :title="`共${state.invoicingInfo.invoiceAmount}元`" name="1">
                <div v-for="item of state.invoicingInfo.invoiceAmountList">{{ item }} 元</div>
              </el-collapse-item>
            </el-collapse>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <div class="build-billInvoicing-text large">收票方信息</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2">
            <div class="build-billInvoicing-text">发票抬头</div>
          </el-col>
          <el-col :offset="1" :span="10">
            <div class="build-billInvoicing-text">{{ state.invoicingDialog.companyInfo.invoiceHeader }}</div>
          </el-col>
          <el-col :span="2" :offset="3">
            <div class="build-billInvoicing-text">电话</div>
          </el-col>
          <el-col :offset="1" :span="5">
            <div class="build-billInvoicing-text">{{ state.invoicingDialog.companyInfo.mobile }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2">
            <div class="build-billInvoicing-text">税号</div>
          </el-col>
          <el-col :offset="1" :span="10">
            <div class="build-billInvoicing-text">{{ state.invoicingDialog.companyInfo.taxIdNumber }}</div>
          </el-col>
          <el-col :span="2" :offset="3">
            <div class="build-billInvoicing-text">开户行</div>
          </el-col>
          <el-col :offset="1" :span="5">
            <div class="build-billInvoicing-text">{{ state.invoicingDialog.companyInfo.openingBank }}</div>
          </el-col>
        </el-row>
  
        <el-row>
          <el-col :span="2">
            <div class="build-billInvoicing-text">地址</div>
          </el-col>
          <el-col :offset="1" :span="10">
            <div class="build-billInvoicing-text">{{ state.invoicingDialog.companyInfo.address }}</div>
          </el-col>
          <el-col :span="2" :offset="3">
            <div class="build-billInvoicing-text">银行账号</div>
          </el-col>
          <el-col :offset="1" :span="5">
            <div class="build-billInvoicing-text">{{ state.invoicingDialog.companyInfo.bankAccount }}</div>
          </el-col>
        </el-row>
  
        <el-row>
          <el-col :span="3">
            <div class="build-billInvoicing-text large">开票方信息</div>
          </el-col>
          <el-col :offset="1" :span="10">
            <el-button type="text" class="operation-records-button" style="margin-top: 5px;" @click="state.invoicingDialog.businessLicenseVisible = true">商家资质信息</el-button>
          </el-col>
        </el-row>
  
        <el-row>
          <el-col :span="2">
            <div class="build-billInvoicing-text">企业名称</div>
          </el-col>
          <el-col :offset="1" :span="12">
            <div class="build-billInvoicing-text">{{ state.invoicingInfo.supplierName ?? state.invoicingDialog.supplierInfo.supplierName }}</div>
          </el-col>
          <el-col :span="2" :offset="1">
            <div class="build-billInvoicing-text">联系电话</div>
          </el-col>
          <el-col :offset="1" :span="3">
            <div class="build-billInvoicing-text">{{ state.invoicingInfo.supplierMobile ?? state.invoicingDialog.supplierInfo.contactMobile }}</div>
          </el-col>
        </el-row>
      </div>
      <template #footer>
        <el-button @click="$emit('update:visible', false)">返回</el-button>
        <el-button type="primary" @click="$emit('toSubmit')" v-if="!isMemberStore && [3, 5].includes(state.invoicingInfo.auditStatus)">{{ buttonText }}</el-button>
        <el-button type="primary" @click="toAudit" v-if="isMemberStore && [4].includes(state.invoicingInfo.auditStatus)">审核</el-button>
      </template>
    </el-dialog>
    <el-dialog
      title="发票审核"
      :visible.sync="state.invoicingDialog.auditVisible"
      width="800"
      modal
      center
      destroy-on-close
    >
      <el-form ref="invoicingFormRef"
        :model="state.audit"
        label-width="150px"
      >
        <el-form-item
          label="审核意见："
          prop="status"
          :rules="rules.status"
          required
        >
          <el-radio-group v-model="state.audit.status"> 
            <el-radio :label="6">通过</el-radio>
            <el-radio :label="5">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="原因说明："
          prop="reason"
          v-if="state.audit.status !== 6"
          :rules="rules.reason"
        >
          <el-input
            type="textarea"
            autosize
            placeholder="请输入内容"
            :autosize="{ minRows: 6, maxRows: 7}"
            maxlength="200"
            show-word-limit
            v-model="state.audit.reason">
          </el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="state.invoicingDialog.auditVisible = false">取消</el-button>
        <el-button type="primary" @click="audit" >确定</el-button>
      </template>
    </el-dialog>
    <el-dialog
      title="营业执照"
      :visible.sync="state.invoicingDialog.businessLicenseVisible"
      width="400px"
      modal
      center
      destroy-on-close
    >
      <div class="businessLicenseContainer">
        <upImage 
          type="string"
          :imageList="getBusinessLicenseImage()"
          :num="1"
          :disabled="true"
        />
      </div>
    </el-dialog>
    <log 
      :visible.sync="state.invoicingDialog.logVisible"
      :id="state.invoicingInfo.supplyBillingInvoiceId"
    />
  </div>
</template>

<script setup>
  import { reactive, onMounted, computed, watch, ref } from 'vue'
  import scm from '@/http/nb/scm'
  import user from '@/http/nb/user'
  import http from '@/http/axios'
  import upload from '@/components/common/upload/index.vue'
  import upImage from '@/components/common/upImage/index.vue'
  import dayjs from 'dayjs'
  import log from './log.vue'
  import { Message } from 'element-ui'

  const props = defineProps({
    visible: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default() {
        return []
      }
    }
  })

  const emits = defineEmits(['update:visible', 'toSubmit'])
  const invoicingFormRef = ref()

  const { isMemberStore } = JSON.parse(localStorage.getItem('userInfo'));

  const rules = {
    status: [{
      required: true, message: '请选择订单审核意见', trigger: 'change'
    }],
    reason: [{
      validator(rule, value, callback) {
        if (state.audit.status === 5 && value?.trim() === '') {
          callback(new Error('驳回请输入原因'))
        } else {
          callback()
        }
      }, 
      trigger: 'change'
    }]
  }

  const state = reactive({
    invoicingDialog: {
      step: 1,
      visible: false,
      logVisible: false,
      auditVisible: false,
      businessLicenseVisible: false,
      collapseActive1: '',
      collapseActive2: '',
      companyInfo: {},
      deliveryAddress: '',
      supplierInfo: {},
      logisticList: [],
    },
    audit: {
      status: '',
      reason: '',
    },
    invoicingInfo: {
      invoiceAmount: 0,
      invoiceAmountList: [],
      billingInvoiceNo: '',
      billingMonth: [],
      invoiceFileUrl: '',
      invoiceRate: '',
      invoiceSpecies: 1,
      invoiceType: 1,
      logisticName: '',
      logisticCode: '',
      logisticNo: '',
      supplierId: '',
      supplierName: '',
      supplierMobile: '',
      businessLicense: '',
      id: ''
    }
  })

  const address = computed(() => {
    const regRes = /([^\(（]*)([\(（].*[\)）])(.*)/.exec(state.invoicingDialog.deliveryAddress)
    return regRes ?? []
  })


  watch(() => props.selected, (newValue)=>{
    state.invoicingInfo.supplyBillingInvoiceId = newValue?.[0]?.id ?? ''
    state.invoicingInfo.billingInvoiceNo = newValue?.[0]?.billingInvoiceNo ?? ''
    state.invoicingInfo.billingMonth = newValue?.map((item)=> item.billingMonth)
    state.invoicingInfo.invoiceAmountList = newValue?.map((item)=> item.settlementAmount)
    state.invoicingInfo.invoiceAmount = newValue?.reduce((total, item)=> total + parseFloat(item.settlementAmount), 0)
    state.invoicingDialog.step = 1;
    state.invoicingDialog.collapseActive1 = '';
    state.invoicingDialog.collapseActive2 = '';
    state.audit.status = '';
    state.audit.reason = '';
    if (state.invoicingInfo.supplyBillingInvoiceId) {
      getInfo()
    }
  })

  const buttonText = computed(()=>{
    let text = ''
    if (state.invoicingInfo.auditStatus === 3) {
      text = '去提交审核'
    } else if(state.invoicingInfo.auditStatus === 5) {
      text = '去修改'
    }
    
    return text
  })

  const titleText = computed(()=>{
    let text = ''
    if (state.invoicingInfo.auditStatus === 3) {
      text = '发票待提交'
    } else if(state.invoicingInfo.auditStatus === 4) {
      text = '发票待审核'
    } else if(state.invoicingInfo.auditStatus === 5) {
      text = '发票已驳回'
    } else if(state.invoicingInfo.auditStatus === 6) {
      text = '发票已通过'
    } 
    
    return text
  })

  function getInfo() {
    http.get(scm.billInvoicing.invoiceDetail, {
      params: {
        supplyBillingInvoiceId: state.invoicingInfo.supplyBillingInvoiceId
      }
    }).then((res)=>{
      if (res?.code === 0) {
        state.invoicingInfo = res.data
      }
    })
  }

  function getBusinessLicenseImage() {
    if (isMemberStore) {
      return state.invoicingInfo.businessLicense
    }
    let url = ''
    for(const item of (state.invoicingDialog?.supplierInfo?.qualificationList ?? [])) {
      if (item.suppliersImageType === '0-3') {
        url = item.suppliersImagePath
        break
      }
    }
    
    return url
  }

  async function getInvoicingDialogInfo() {
    http.get(scm.billInvoicing.companyInfo).then((res)=>{
      if (res?.code === 0) {
        state.invoicingDialog.companyInfo = res.data ?? {}
      }
    })

    http.get(scm.billInvoicing.deliveryAddress).then((res)=>{
      if (res?.code === 0) {
        state.invoicingDialog.deliveryAddress = res.data ?? ''
      }
    })

    http.get(user.settle.getReviewInfoBySupplierId, {
      params: {
        supplierId: JSON.parse(localStorage.getItem('userInfo')).suppliersId,
      }
    }).then((res)=>{
      if (res?.code === 0) {
        state.invoicingDialog.supplierInfo = res.data ?? ''
        if (!isMemberStore) {
          state.invoicingInfo.supplierMobile = res.data?.contactMobile ?? ''
          state.invoicingInfo.supplierName = res.data?.supplierName ?? ''
          state.invoicingInfo.supplierId = res.data?.supplierId ?? ''
        }
      }
    })

    const res = await http.get(user.commonNew.getLogisticList);
    if (res?.code === 0) {
      state.invoicingDialog.logisticList = res.data || [];
    }
  }

  function toAudit() {
    state.invoicingDialog.auditVisible = true
  }

  function audit() {
    invoicingFormRef.value?.validate((isValid)=>{
      if (isValid) {
        http.post(scm.billInvoicing.audit, {
          auditStatus: state.audit.status,
          supplyBillingInvoiceId: state.invoicingInfo.supplyBillingInvoiceId,
          reason: state.audit.reason,
        }).then((res)=>{
          if (res?.code === 0) {
            Message.success('操作成功')
            state.invoicingDialog.auditVisible = false;
            emits('update:visible', false)
            emits('update')
          }
        })
      }
    })
  }


  onMounted(()=>{
    getInvoicingDialogInfo()
  })
</script>

<style lang="scss" scoped>
  .businessLicenseContainer {
    display: flex; 
    justify-items: center; 
    align-items: center;
    flex-direction: row;
    width: 100%; 
    height: 100%;
  }
  .build-billInvoicing-container {
    background-color: #fff;

    .operation-records-button {
      padding: 0;
    }
    .build-billInvoicing-tip {
      margin-left: 68px;
    }

    .build-billInvoicing-header {
      border-radius: 8px;
      border: 1px solid #eee;
    }
    .build-billInvoicing-input-container {
      width: 300px;
      display: inline-block;
    }
    .build-billInvoicing-text {
      &.large {
        font-size: 16px;
        font-weight: bold;
      }
      &.red {
        color: #f00;
      }
      line-height: 24px;
    }
    :deep {
      .el-row {
        margin-bottom: 20px;
      }
      .el-collapse-item__header {
        height: auto;
        line-height: 24px;
        border: none;
      }
      .el-collapse-item__wrap {
        border: none;
      }
      .el-collapse {
        border: none;
      }
    }
  }
</style>