<template>
  <div class="table-container">
    <SearchPanel
      flex
      size="mini"
    >
      <div>
        <p>账单名称：</p>
        <el-input
          v-model="state.searchParams.settlementInterval"
          size="mini"
          placeholder="请输入"
          clearable
        />
      </div>
      <el-button
        slot="button-right"
        size="mini"
        @click="getList"
      >
        查 询
      </el-button>
      <el-button
        slot="button-right"
        size="mini"
        @click="resetList"
      >
        重 置
      </el-button>
      <el-button
        slot="button-right"
        size="mini"
        v-if="!isMemberStore"
        @click="openInvoicing()"
      >
        批量开具发票
      </el-button>
    </SearchPanel>
    <el-table
      v-loading="state.loading"
      element-loading-text="拼命加载中"
      :data="state.tableData"
      border
      :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
      :row-style="{'font-size': '14px', 'color': '#0D0202'}"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        type="index"
        label="序号"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="settlementInterval"
        label="账单名称"
        min-width="200px"
      >
      </el-table-column>
      <el-table-column
        prop="billingMonth"
        label="账单月份"
      />
      <el-table-column
        prop="supplierName"
        label="供应商名称"
      />
      <el-table-column
        prop="settlementAmount"
        label="账单金额（元）"
      />
      <el-table-column
        prop="receiveInvoiceCompany"
        label="收票方主体"
      />
      <el-table-column
        prop="payment"
        label="操作"
      >
        <template #default="{ row }">
          <el-button type="text" @click="openInvoicing(row)" v-if="!isMemberStore">
            开具发票
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="state.tableData && state.tableData.length > 0"
      :current-page="state.page.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="state.page.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="state.page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <invoicingModal 
      :visible.sync="state.invoicingDialog.visible"
      :selected="state.selected"
      @submit="submitFinish"
    />
  </div>
</template>

<script setup>
  import { reactive, onMounted } from 'vue'
  import scm from '@/http/nb/scm'
  import http from '@/http/axios'
  import { Message } from 'element-ui'
  import invoicingModal from './invoicingModal.vue'

  const { isMemberStore } = JSON.parse(localStorage.getItem('userInfo'));

  const state = reactive({
    searchParams: {
      settlementInterval: '',
    },
    invoicingDialog: {
      step: 1,
      visible: false,
      collapseActive1: '',
      collapseActive2: '',
      companyInfo: {},
      deliveryAddress: '',
      supplierInfo: {},
      logisticList: [],
    },
    mutiSelected: [],
    loading: false,
    selected: [],
    tableData: [],
    page: {
      total: 0,
      currentPage: 1,
      pageSize: 10
    }
  })


  function resetList() {
    state.searchParams.settlementInterval = ''
    getList()
  }

  function getList() {
    state.loading = true;
    http.post(scm.billInvoicing.notInvoiced, {
      currentPage: state.page.currentPage,
      pageSize: state.page.pageSize,
      settlementInterval: state.searchParams.settlementInterval
    }).then((res)=>{
      if (res.code === 0) {
        state.tableData = res.data?.records ?? []
        state.page.total = res.data?.total ? Number(res.data?.total) : 0
      }
    }).finally(()=>{
      state.loading = false;
    })
  }

  function handleSizeChange(e) {
    state.page.pageSize = e;
    getList()
  }

  function handleCurrentChange(e) {
    state.page.currentPage = e;
    getList()
  }

  function handleSelectionChange(e) {
    state.mutiSelected = e
  }

  function openInvoicing(e) {
    if (e) {
      state.selected = [e]
    } else {
      state.selected = state.mutiSelected
    }

    if (state.selected.length === 0) {
      return Message.warning('请至少选择一个账单')
    }
    state.invoicingDialog.visible = true
  }

  function submitFinish() {
    getList();
    state.invoicingDialog.visible = false
  }

  onMounted(()=>{
    getList()
  })
</script>

<style lang="scss" scoped>
   .billInvoicing-container {
    .collapse-container {
      padding: 20px;
      background-color: #fff;
      .intr-container {
        .flow-path-container {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .flow-path-title-container {
            margin-right: 30px;
            flex-shrink: 0;
          }
          .flow-path-content-container {
            flex-grow: 1;
          }
        }
      }
    }
    .tab-container {
      background-color: #fff;
      padding: 20px;
    }
    .table-container {
      padding: 20px;
      background-color: #fff;
    }
  }
  
  .build-billInvoicing-container {
    background-color: #fff;
    .build-billInvoicing-tip {
      margin-left: 68px;
    }

    .build-billInvoicing-input-container {
      width: 300px;
      display: inline-block;
    }
    .build-billInvoicing-text {
      &.large {
        font-size: 16px;
        font-weight: bold;
      }
      &.red {
        color: #f00;
      }
      line-height: 24px;
    }
    :deep {
      .el-row {
        margin-bottom: 20px;
      }
      .el-collapse-item__header {
        height: auto;
        line-height: 24px;
        border: none;
      }
      .el-collapse-item__wrap {
        border: none;
      }
      .el-collapse {
        border: none;
      }
    }
  }
</style>