var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "开票详情",
            visible: _vm.visible,
            width: "800px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$emit("update:visible", $event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("update:visible", false)
                        },
                      },
                    },
                    [_vm._v("返回")]
                  ),
                  !_setup.isMemberStore &&
                  [3, 5].includes(_setup.state.invoicingInfo.auditStatus)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("toSubmit")
                            },
                          },
                        },
                        [_vm._v(_vm._s(_setup.buttonText))]
                      )
                    : _vm._e(),
                  _setup.isMemberStore &&
                  [4].includes(_setup.state.invoicingInfo.auditStatus)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _setup.toAudit },
                        },
                        [_vm._v("审核")]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "div",
            { staticClass: "build-billInvoicing-container" },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c(
                      "div",
                      { staticClass: "build-billInvoicing-text large" },
                      [_vm._v(_vm._s(_setup.titleText))]
                    ),
                  ]),
                ],
                1
              ),
              _setup.state.invoicingInfo.auditStatus === 5
                ? _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("div", { staticClass: "build-billInvoicing-text" }, [
                          _vm._v("驳回原因"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { offset: 1, span: 21 } }, [
                        _c("div", { staticClass: "build-billInvoicing-text" }, [
                          _vm._v(_vm._s(_setup.state.invoicingInfo.reason)),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("开票单据号"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { offset: 1, span: 7 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(_setup.state.invoicingInfo.billingInvoiceNo)
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("创建时间"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { offset: 1, span: 5 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup
                            .dayjs(
                              Number(_setup.state.invoicingInfo.createTime)
                            )
                            .format("YYYY-MM-DD HH:mm:ss")
                        )
                      ),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 2, offset: 3 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "operation-records-button",
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              _setup.state.invoicingDialog.logVisible = true
                            },
                          },
                        },
                        [_vm._v("操作记录")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c(
                      "div",
                      { staticClass: "build-billInvoicing-text large" },
                      [_vm._v("发票信息")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("发票类型"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { offset: 1, span: 11 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingInfo.invoiceType === 1
                            ? "电子发票"
                            : "纸质发票"
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("发票种类"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { offset: 1, span: 11 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingInfo.invoiceSpecies === 1
                            ? "增值税专用发票"
                            : "增值税普通发票"
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("上传发票"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { offset: 1, span: 21 } },
                    [
                      _c(_setup.upload, {
                        attrs: {
                          disabled: "",
                          files: _setup.state.invoicingInfo.invoiceFileUrl,
                          downloadFileName:
                            _setup.state.invoicingInfo.billingInvoiceNo,
                          num: 5,
                          type: "string",
                        },
                        on: {
                          "update:files": function ($event) {
                            return _vm.$set(
                              _setup.state.invoicingInfo,
                              "invoiceFileUrl",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("发票税率"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { offset: 1, span: 11 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingInfo.invoiceRate
                            ? `${_setup.state.invoicingInfo.invoiceRate}%`
                            : ""
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _setup.state.invoicingInfo.invoiceType === 2
                ? _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 10 } }, [
                        _c(
                          "div",
                          { staticClass: "build-billInvoicing-text large" },
                          [_vm._v("邮寄信息")]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _setup.state.invoicingInfo.invoiceType === 2
                ? _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("div", { staticClass: "build-billInvoicing-text" }, [
                          _vm._v("收件信息"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { offset: 1, span: 20 } }, [
                        _c("div", { staticClass: "build-billInvoicing-text" }, [
                          _c("div", [
                            _c("div", [
                              _vm._v(_vm._s(_setup.address?.[1]) + " "),
                              _c("span", { staticClass: "red" }, [
                                _vm._v(_vm._s(_setup.address?.[2])),
                              ]),
                            ]),
                            _c("div", [_vm._v(_vm._s(_setup.address?.[3]))]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _setup.state.invoicingInfo.invoiceType === 2
                ? _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("div", { staticClass: "build-billInvoicing-text" }, [
                          _vm._v("物流单号"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { offset: 1, span: 11 } }, [
                        _c("div", { staticClass: "build-billInvoicing-text" }, [
                          _vm._v(_vm._s(_setup.state.invoicingInfo.logisticNo)),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _setup.state.invoicingInfo.invoiceType === 2
                ? _c(
                    "el-row",
                    [
                      _c("el-col", { attrs: { span: 2 } }, [
                        _c("div", { staticClass: "build-billInvoicing-text" }, [
                          _vm._v("物流公司"),
                        ]),
                      ]),
                      _c("el-col", { attrs: { offset: 1, span: 10 } }, [
                        _c("div", { staticClass: "build-billInvoicing-text" }, [
                          _vm._v(
                            _vm._s(_setup.state.invoicingInfo.logisticName)
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      { staticClass: "build-billInvoicing-text large" },
                      [_vm._v("账单信息")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", [
                        _vm._v(
                          "请将账单月份打印在发票备注信息部分，便于平台审核"
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("账单月份"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { offset: 1, span: 4 } },
                    [
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _setup.state.invoicingDialog.collapseActive1,
                            callback: function ($$v) {
                              _vm.$set(
                                _setup.state.invoicingDialog,
                                "collapseActive1",
                                $$v
                              )
                            },
                            expression: "state.invoicingDialog.collapseActive1",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              attrs: {
                                title: `共${_setup.state.invoicingInfo.billingMonth.length}个月`,
                                name: "1",
                              },
                            },
                            _vm._l(
                              _setup.state.invoicingInfo.billingMonth,
                              function (item) {
                                return _c("div", [_vm._v(_vm._s(item))])
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 2, offset: 9 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("开票金额"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { offset: 1, span: 4 } },
                    [
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _setup.state.invoicingDialog.collapseActive2,
                            callback: function ($$v) {
                              _vm.$set(
                                _setup.state.invoicingDialog,
                                "collapseActive2",
                                $$v
                              )
                            },
                            expression: "state.invoicingDialog.collapseActive2",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              attrs: {
                                title: `共${_setup.state.invoicingInfo.invoiceAmount}元`,
                                name: "1",
                              },
                            },
                            _vm._l(
                              _setup.state.invoicingInfo.invoiceAmountList,
                              function (item) {
                                return _c("div", [_vm._v(_vm._s(item) + " 元")])
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c(
                      "div",
                      { staticClass: "build-billInvoicing-text large" },
                      [_vm._v("收票方信息")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("发票抬头"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { offset: 1, span: 10 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingDialog.companyInfo.invoiceHeader
                        )
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 2, offset: 3 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("电话"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { offset: 1, span: 5 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(_setup.state.invoicingDialog.companyInfo.mobile)
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("税号"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { offset: 1, span: 10 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingDialog.companyInfo.taxIdNumber
                        )
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 2, offset: 3 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("开户行"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { offset: 1, span: 5 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingDialog.companyInfo.openingBank
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("地址"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { offset: 1, span: 10 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(_setup.state.invoicingDialog.companyInfo.address)
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 2, offset: 3 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("银行账号"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { offset: 1, span: 5 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingDialog.companyInfo.bankAccount
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      { staticClass: "build-billInvoicing-text large" },
                      [_vm._v("开票方信息")]
                    ),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { offset: 1, span: 10 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "operation-records-button",
                          staticStyle: { "margin-top": "5px" },
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              _setup.state.invoicingDialog.businessLicenseVisible = true
                            },
                          },
                        },
                        [_vm._v("商家资质信息")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("企业名称"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { offset: 1, span: 12 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingInfo.supplierName ??
                            _setup.state.invoicingDialog.supplierInfo
                              .supplierName
                        )
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 2, offset: 1 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("联系电话"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { offset: 1, span: 3 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingInfo.supplierMobile ??
                            _setup.state.invoicingDialog.supplierInfo
                              .contactMobile
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "发票审核",
            visible: _setup.state.invoicingDialog.auditVisible,
            width: "800",
            modal: "",
            center: "",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _setup.state.invoicingDialog,
                "auditVisible",
                $event
              )
            },
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _setup.state.invoicingDialog.auditVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _setup.audit } },
                    [_vm._v("确定")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-form",
            {
              ref: "invoicingFormRef",
              attrs: { model: _setup.state.audit, "label-width": "150px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "审核意见：",
                    prop: "status",
                    rules: _setup.rules.status,
                    required: "",
                  },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _setup.state.audit.status,
                        callback: function ($$v) {
                          _vm.$set(_setup.state.audit, "status", $$v)
                        },
                        expression: "state.audit.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 6 } }, [_vm._v("通过")]),
                      _c("el-radio", { attrs: { label: 5 } }, [_vm._v("驳回")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _setup.state.audit.status !== 6
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "原因说明：",
                        prop: "reason",
                        rules: _setup.rules.reason,
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          autosize: "",
                          placeholder: "请输入内容",
                          autosize: { minRows: 6, maxRows: 7 },
                          maxlength: "200",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _setup.state.audit.reason,
                          callback: function ($$v) {
                            _vm.$set(_setup.state.audit, "reason", $$v)
                          },
                          expression: "state.audit.reason",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "营业执照",
            visible: _setup.state.invoicingDialog.businessLicenseVisible,
            width: "400px",
            modal: "",
            center: "",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _setup.state.invoicingDialog,
                "businessLicenseVisible",
                $event
              )
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "businessLicenseContainer" },
            [
              _c(_setup.upImage, {
                attrs: {
                  type: "string",
                  imageList: _setup.getBusinessLicenseImage(),
                  num: 1,
                  disabled: true,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(_setup.log, {
        attrs: {
          visible: _setup.state.invoicingDialog.logVisible,
          id: _setup.state.invoicingInfo.supplyBillingInvoiceId,
        },
        on: {
          "update:visible": function ($event) {
            return _vm.$set(_setup.state.invoicingDialog, "logVisible", $event)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }