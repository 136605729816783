var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "app-container" }, [
    _c(
      "div",
      { staticClass: "billInvoicing-container" },
      [
        _c(
          "div",
          { staticClass: "collapse-container" },
          [
            _c(
              "el-collapse",
              {
                model: {
                  value: _setup.state.collapseActive,
                  callback: function ($$v) {
                    _vm.$set(_setup.state, "collapseActive", $$v)
                  },
                  expression: "state.collapseActive",
                },
              },
              [
                _c(
                  "el-collapse-item",
                  {
                    attrs: { name: "1" },
                    scopedSlots: _vm._u([
                      {
                        key: "title",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "status-header" }, [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _setup.state.collapseActive.includes("1")
                                        ? "关闭"
                                        : "展开"
                                    )
                                ),
                              ]),
                              _c("i", {
                                staticClass:
                                  "el-icon-arrow-right status-header_icon",
                              }),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _c("div", { staticClass: "intr-container" }, [
                      _c("div", { staticClass: "flow-path-container" }, [
                        _c(
                          "div",
                          { staticClass: "flow-path-title-container" },
                          [_vm._v("供应链开票流程")]
                        ),
                        _c(
                          "div",
                          { staticClass: "flow-path-content-container" },
                          [
                            _c(
                              "el-steps",
                              { attrs: { active: 1, simple: "" } },
                              [
                                _c("el-step", {
                                  attrs: {
                                    title: "选择目标账单",
                                    status: "process",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "icon",
                                      fn: function () {
                                        return [_c("div", [_vm._v("1")])]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("el-step", {
                                  attrs: {
                                    title: "开具发票",
                                    status: "process",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "icon",
                                      fn: function () {
                                        return [_c("div", [_vm._v("2")])]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("el-step", {
                                  attrs: {
                                    title: "优选处理",
                                    status: "process",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "icon",
                                      fn: function () {
                                        return [_c("div", [_vm._v("3")])]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                                _c("el-step", {
                                  attrs: {
                                    title: "查看处理结果",
                                    status: "process",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "icon",
                                      fn: function () {
                                        return [_c("div", [_vm._v("4")])]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "flow-path-container" }, [
                        _c(
                          "div",
                          { staticClass: "flow-path-title-container" },
                          [_vm._v("开票说明")]
                        ),
                        _c("div", [
                          _c("div", [
                            _vm._v(
                              "开票原因：商品成交时，订货单包含的货款，结算时计入商家的供应链资金收入，商家需开具等额发票。"
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              "开票种类：一般纳税人请开具增值税专用发票，小规模纳税人请先向主管税务机关申请代开增值税专用发票，若开具困难可开具增值税普通发票。"
                            ),
                          ]),
                          _c("div", [
                            _vm._v(
                              "对账时效：每月对账单确认后请尽快开票，未开票时供应链资金账户将冻结相应的金额，冻结资金无法提现。"
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "tab-container" },
          [
            _c(
              "el-tabs",
              {
                attrs: { value: _setup.state.tabActive },
                on: { "tab-click": _setup.handleTabs },
              },
              [
                _c("el-tab-pane", { attrs: { label: "未处理", name: "1" } }),
                _c("el-tab-pane", { attrs: { label: "处理中", name: "2" } }),
                _c("el-tab-pane", { attrs: { label: "已通过", name: "3" } }),
              ],
              1
            ),
          ],
          1
        ),
        _setup.state.tabActive === "1"
          ? _c(_setup.notInvoice)
          : _setup.state.tabActive === "2"
          ? _c(_setup.handle)
          : _setup.state.tabActive === "3"
          ? _c(_setup.pass)
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }