export const billingInvoiceType = {
   1: '通过', 
   2: '修改', 
   3: '驳回', 
   4: '提审', 
   5: '创建'
}

export const invoiceType = {
  1: '电子发票',
  2: '纸质发票'
}

export const invoiceSpecies = {
  1: '专票',
  2: '普票'
}

export const statusText = [{
  label: '待提交',
  value: 3,
}, {
  label: '待审核',
  value: 4,
}, {
  label: '已驳回',
  value: 5,
}, {
  label: '已通过',
  value: 6,
}]