<template>
  <div class="table-container">
    <SearchPanel
      flex
      size="mini"
    >
      <div>
        <p>开票单据号：</p>
        <el-input
          v-model="state.searchParams.billingInvoiceNo"
          size="mini"
          placeholder="请输入"
          clearable
        />
      </div>
      <div>
        <p>状态：</p>
        <el-select
          v-model="auditStatus"
          placeholder="请选择"
          size="mini"
          filterable
          clearable
        >
          <el-option
            v-for="item of statusText"
            :key="item['value']"
            :label="item['label']"
            :value="item['value']"
          />
        </el-select>
      </div>
      <div>
        <p>发票类型：</p>
        <el-select
          v-model="state.searchParams.invoiceType"
          placeholder="请选择"
          size="mini"
          filterable
          clearable
        >
          <el-option
            label="电子发票"
            :value="1"
          />
          <el-option
            label="纸质发票"
            :value="2"
          />
        </el-select>
      </div>
      <div>
        <p>发票种类：</p>
        <el-select
          v-model="state.searchParams.invoiceSpecies"
          placeholder="请选择"
          size="mini"
          filterable
          clearable
        >
          <el-option
            label="专票"
            :value="1"
          />
          <el-option
            label="普票"
            :value="2"
          />
        </el-select>
      </div>
      <div>
        <p>供应商：</p>
        <el-input
          v-model="state.searchParams.supplierName"
          size="mini"
          placeholder="请输入"
          clearable
        />
      </div>
      <div>
        <p>创建时间：</p>
        <el-date-picker
          v-model="datePickerTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="timestamp"
          size="mini"
        />
      </div>
      <el-button
        slot="button-right"
        size="mini"
        @click="getList"
      >
        查 询
      </el-button>
      <el-button
        slot="button-right"
        size="mini"
        @click="resetInvoicedList"
      >
        重 置
      </el-button>
    </SearchPanel>
    <el-table
      v-loading="state.loading"
      element-loading-text="拼命加载中"
      :data="state.tableData"
      border
      :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
      :row-style="{'font-size': '14px', 'color': '#0D0202'}"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        type="index"
        label="序号"
        width="100"
      >
      </el-table-column>
      <el-table-column
        prop="billingInvoiceNo"
        label="开票单据号"
        min-width="200px"
      >
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
      >
        <template #default="{row}">
          <div>{{ dayjs(Number(row.createTime)).format('YYYY-MM-DD HH:mm:ss') }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="invoiceAmount"
        label="开票金额（元）"
      />
      <el-table-column
        prop="auditStatus"
        label="状态"
      >
        <template #default="{ row }">
          <div>{{ getStatus(row.auditStatus) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="invoiceType"
        label="发票类型"
      >
        <template #default="{ row }">
          {{ getInvoicedTypeText(row) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="invoiceSpecies"
        label="发票种类"
      >
        <template #default="{ row }">
          {{ getInvoiceSpeciesText(row) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="supplierName"
        label="供应商"
      />
      <el-table-column
        prop="payment"
        label="操作"
      >
        <template #default="{ row }">
          <template v-if="!isMemberStore">
            <el-button type="text" v-if="row.auditStatus === 4" @click="openInvoicing(row, 'info', 'view')">
              查看
            </el-button>
            <el-button type="text" v-else @click="openInvoicing(row)">
              查看并处理
            </el-button>
          </template>
          <template v-else>
            <el-button type="text" v-if="row.auditStatus === 4" @click="openInvoicing(row, 'info', 'view')">
              查看并处理
            </el-button>
            <el-button type="text" v-else @click="openInvoicing(row, 'info', 'view')">
              查看
            </el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="state.tableData && state.tableData.length > 0"
      :current-page="state.page.currentPage"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="state.page.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="state.page.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <invoicingModal 
      :visible.sync="state.invoicingDialog.visible"
      :selected="state.selected"
      @submit="submitFinish"
      @handleTabs="$emit('handleTabs', $event)"
    />
    <invoicingInfoModal 
      :visible.sync="state.invoicingDialog.infoVisible"
      :selected="state.selected"
      :mode="state.invoicingDialog.mode"
      @update="getList"
      @toSubmit="toSubmit"
    />
  </div>
</template>

<script setup>
  import { reactive, onMounted, computed } from 'vue'
  import scm from '@/http/nb/scm'
  import http from '@/http/axios'
  import { Message } from 'element-ui'
  import invoicingModal from './invoicingModal.vue'
  import invoicingInfoModal from './invoicingInfoModal.vue'
  import dayjs from 'dayjs'
  import { invoiceType, invoiceSpecies, statusText as statusAll } from './constant'
  
  const { isMemberStore } = JSON.parse(localStorage.getItem('userInfo'));
  
  const state = reactive({
    searchParams: {
      billingInvoiceNo: '',
      invoiceSpecies: '',
      auditStatusList: [3, 4, 5],
      invoiceType: '',
      beginTime: dayjs(new Date(new Date().getTime() - 3600 * 1000 * 24 * 30)),
      endTime: dayjs(new Date()),
      supplierName: ''
    },
    invoicingDialog: {
      visible: false,
      infoVisible: false,
      mode: 'view'
    },
    invoicingInfo: {
      invoiceAmount: 0,
      invoiceAmountList: [],
      billingInvoiceNo: '',
      billingMonth: [],
      invoiceFileUrl: '',
      invoiceRate: '',
      invoiceSpecies: 1,
      invoiceType: 1,
      logisticName: '',
      logisticCode: '',
      logisticNo: '',
      supplierId: ''
    },
    loading: false,
    selected: [],
    tableData: [],
    page: {
      total: 0,
      currentPage: 1,
      pageSize: 10
    }
  })

  const statusText = statusAll.filter((item)=>item.value !== 6)

  const auditStatus = computed({
    get() {
      if (state.searchParams.auditStatusList.length > 1) {
        return ''
      }
      return state.searchParams.auditStatusList[0]
    },
    set(val) {
      if (val === '') {
        return state.searchParams.auditStatusList = [3, 4, 5]
      } 
      state.searchParams.auditStatusList = [val]
    }
  })

  const datePickerTime = computed({
    get() {
      return [state.searchParams.beginTime, state.searchParams.endTime]
    },
    set(newValue) {
      state.searchParams.beginTime = newValue?.[0];
      state.searchParams.endTime = newValue?.[1];
    }
  })

  function getInvoicedTypeText(row) {
    let invoiceTypeText = invoiceType[row.invoiceType]
    return invoiceTypeText
  }

  function getInvoiceSpeciesText(row) {
    const invoiceSpeciesText = invoiceSpecies[row.invoiceSpecies]
    return invoiceSpeciesText
  }

  function getStatus(statusVal) {
    const item = statusText.find((item)=>item.value === statusVal)
    return item?.label
  }

  function resetInvoicedList() {
    state.searchParams = {
      billingInvoiceNo: '',
      auditStatusList: [3, 4, 5],
      invoiceType: '',
      beginTime: dayjs(new Date(new Date().getTime() - 3600 * 1000 * 24 * 30)),
      endTime: dayjs(new Date()),
      supplierName: ''
    }
    getList()
  }

  function getList() {
    state.loading = true;
    http.post(scm.billInvoicing.invoiceList, {
      ...state.searchParams,
      currentPage: state.page.currentPage,
      pageSize: state.page.pageSize,
    }).then((res)=>{
      if (res.code === 0) {
        state.tableData = res.data?.records ?? []
        state.page.total = res.data?.total ? Number(res.data?.total) : 0
      }
    }).finally(()=>{
      state.loading = false;
    })
  }

  function handleSizeChange(e) {
    state.page.pageSize = e;
    getList()
  }

  function handleCurrentChange(e) {
    state.page.currentPage = e;
    getList()
  }

  function handleSelectionChange(e) {
    state.selected = e
  }

  function openInvoicing(e, type='info', mode='edit') {
    if (e) {
      state.selected = [e]
    } 

    if (state.selected.length === 0) {
      return Message.warning('请至少选择一个账单')
    }
    if (type === 'info') {
      state.invoicingDialog.infoVisible = true
      state.invoicingDialog.visible = false
      state.invoicingDialog.mode = mode;
      return 
    } 
    state.invoicingDialog.visible = true
    state.invoicingDialog.infoVisible = false
  }

  function submitFinish() {
    getList();
    state.invoicingDialog.visible = false
  }

  function toSubmit() {
    state.invoicingDialog.infoVisible = false
    state.invoicingDialog.visible = true
  }

  onMounted(()=>{
    getList()
  })
</script>

<style lang="scss" scoped>
   .billInvoicing-container {
    .collapse-container {
      padding: 20px;
      background-color: #fff;
      .intr-container {
        .flow-path-container {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .flow-path-title-container {
            margin-right: 30px;
            flex-shrink: 0;
          }
          .flow-path-content-container {
            flex-grow: 1;
          }
        }
      }
    }
    .tab-container {
      background-color: #fff;
      padding: 20px;
    }
    .table-container {
      padding: 20px;
      background-color: #fff;
    }
  }
  
  .build-billInvoicing-container {
    background-color: #fff;
    .build-billInvoicing-tip {
      margin-left: 68px;
    }

    .build-billInvoicing-input-container {
      width: 300px;
      display: inline-block;
    }
    .build-billInvoicing-text {
      &.large {
        font-size: 16px;
        font-weight: bold;
      }
      &.red {
        color: #f00;
      }
      line-height: 24px;
    }
    :deep {
      .el-row {
        margin-bottom: 20px;
      }
      .el-collapse-item__header {
        height: auto;
        line-height: 24px;
        border: none;
      }
      .el-collapse-item__wrap {
        border: none;
      }
      .el-collapse {
        border: none;
      }
    }
  }
</style>