<template>
  <div class="app-container">
    <div class="billInvoicing-container">
      <div class="collapse-container">
        <el-collapse v-model="state.collapseActive">
          <el-collapse-item name="1">
            <template #title>
              <div class="status-header">
                <span> {{ state.collapseActive.includes('1') ? '关闭' : '展开' }}</span>
                <i class="el-icon-arrow-right status-header_icon"></i>
              </div>
            </template>
            <div class="intr-container">
              <div class="flow-path-container">
                <div class="flow-path-title-container">供应链开票流程</div>
                <div class="flow-path-content-container">
                  <el-steps :active="1" simple>
                    <el-step title="选择目标账单" status="process">
                      <template #icon>
                        <div>1</div>
                      </template>
                    </el-step>
                    <el-step title="开具发票" status="process">
                      <template #icon>
                        <div>2</div>
                      </template>
                    </el-step>
                    <el-step title="优选处理" status="process">
                      <template #icon>
                        <div>3</div>
                      </template>
                    </el-step>
                    <el-step title="查看处理结果" status="process">
                      <template #icon>
                        <div>4</div>
                      </template>
                    </el-step>
                  </el-steps>
                </div>
              </div>
              <div class="flow-path-container">
                <div class="flow-path-title-container">开票说明</div>
                <div>
                  <div>开票原因：商品成交时，订货单包含的货款，结算时计入商家的供应链资金收入，商家需开具等额发票。</div>
                  <div>开票种类：一般纳税人请开具增值税专用发票，小规模纳税人请先向主管税务机关申请代开增值税专用发票，若开具困难可开具增值税普通发票。</div>
                  <div>对账时效：每月对账单确认后请尽快开票，未开票时供应链资金账户将冻结相应的金额，冻结资金无法提现。</div>
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="tab-container">
        <el-tabs :value="state.tabActive" @tab-click="handleTabs">
          <el-tab-pane label="未处理" name="1"></el-tab-pane>
          <el-tab-pane label="处理中" name="2"></el-tab-pane>
          <el-tab-pane label="已通过" name="3"></el-tab-pane>
        </el-tabs>
      </div>
      <notInvoice v-if="state.tabActive === '1'" />
      <handle v-else-if="state.tabActive === '2'" />
      <pass v-else-if="state.tabActive === '3'" />
    </div>
  </div>
</template>
<script setup>
  import { reactive } from 'vue'
  import notInvoice from './notInvoice.vue'
  import handle from './handle.vue'
  import pass from './pass.vue'

  const state = reactive({
    tabActive: '1',
    collapseActive: ['1'],
  })

  function handleTabs(tab) {
    state.tabActive = tab.name
  }

</script>

<style scoped lang="scss">
  .billInvoicing-container {
    .collapse-container {
      padding: 20px;
      background-color: #fff;
      .intr-container {
        .flow-path-container {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .flow-path-title-container {
            margin-right: 30px;
            flex-shrink: 0;
          }
          .flow-path-content-container {
            flex-grow: 1;
          }
        }
      }
    }
    .tab-container {
      background-color: #fff;
      padding: 20px;
    }
    .table-container {
      padding: 20px;
      background-color: #fff;
    }
  }

  .collapse-container {
    :deep {
      .el-collapse-item__header.is-active {
        .status-header { 
          .status-header_icon {
            display: flex;
            align-items: center;
            margin-left: 8px;
            transform: rotate(90deg);
          }
        }
      }
    }

    .status-header {
      margin: 0 8px 0 auto;
      display: flex;
      justify-content: center;
      .status-header_icon {
        display: flex;
        align-items: center;
        margin-left: 8px;
      }
    }
    :deep {
      .el-collapse-item__arrow {
        display: none;
      }
    }
  }
  
</style>