<template>
  <el-dialog
    title="开具发票"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
    center
    width="800px"
  >
    <div class="build-billInvoicing-container" v-if="state.invoicingDialog.step === 1">
      <el-row>
        <el-col :span="3">
          <div class="build-billInvoicing-text large">账单信息</div>
        </el-col>
        <el-col :span="12">
          <div class="build-billInvoicing-text">
            <i class="el-icon-warning"></i>
            <span class="red">请将账单月份打印在发票备注信息部分，便于平台审核</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2">
          <div class="build-billInvoicing-text">账单月份</div>
        </el-col>
        <el-col :span="4">
          <el-collapse v-model="state.invoicingDialog.collapseActive1">
            <el-collapse-item :title="`共${state.invoicingInfo.billingMonth.length}个月`"  name="1">
              <div v-for="item of state.invoicingInfo.billingMonth">{{ item }}</div>
            </el-collapse-item>
          </el-collapse>
        </el-col>
        <el-col :span="2" :offset="10">
          <div class="build-billInvoicing-text">开票金额</div>
        </el-col>
        <el-col :span="4">
          <el-collapse v-model="state.invoicingDialog.collapseActive2">
            <el-collapse-item :title="`共${state.invoicingInfo.invoiceAmount}元`" name="1">
              <div v-for="item of state.invoicingInfo.invoiceAmountList">{{ item }} 元</div>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <div class="build-billInvoicing-text large">收票方信息</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2">
          <div class="build-billInvoicing-text">发票抬头</div>
        </el-col>
        <el-col :span="10">
          <div class="build-billInvoicing-text">{{ state.invoicingDialog.companyInfo.invoiceHeader }}</div>
        </el-col>
        <el-col :span="2" offset="4">
          <div class="build-billInvoicing-text">电话</div>
        </el-col>
        <el-col :span="6">
          <div class="build-billInvoicing-text">{{ state.invoicingDialog.companyInfo.mobile }}</div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="2">
          <div class="build-billInvoicing-text">税号</div>
        </el-col>
        <el-col :span="10">
          <div class="build-billInvoicing-text">{{ state.invoicingDialog.companyInfo.taxIdNumber }}</div>
        </el-col>
        <el-col :span="2" offset="4">
          <div class="build-billInvoicing-text">开户行</div>
        </el-col>
        <el-col :span="6">
          <div class="build-billInvoicing-text">{{ state.invoicingDialog.companyInfo.openingBank }}</div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="2">
          <div class="build-billInvoicing-text">地址</div>
        </el-col>
        <el-col :span="13">
          <div class="build-billInvoicing-text">{{ state.invoicingDialog.companyInfo.address }}</div>
        </el-col>
        <el-col :span="2" offset="1">
          <div class="build-billInvoicing-text">银行账号</div>
        </el-col>
        <el-col :span="6">
          <div class="build-billInvoicing-text">{{ state.invoicingDialog.companyInfo.bankAccount }}</div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="10">
          <div class="build-billInvoicing-text large">开票方信息</div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="2">
          <div class="build-billInvoicing-text">企业名称</div>
        </el-col>
        <el-col :span="13">
          <div class="build-billInvoicing-text">{{ state.invoicingDialog.supplierInfo.supplierName }}</div>
        </el-col>
        <el-col :span="2" offset="1">
          <div class="build-billInvoicing-text">联系电话</div>
        </el-col>
        <el-col :span="6">
          <div class="build-billInvoicing-text">{{ state.invoicingDialog.supplierInfo.contactMobile }}</div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="10">
          <div class="build-billInvoicing-text large">推荐开票内容</div>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="2">
          <div class="build-billInvoicing-text">开票内容</div>
        </el-col>
        <el-col :span="15">
          <div class="build-billInvoicing-text red">请参照对账单明细，数量、金额须一致</div>
        </el-col>
      </el-row>
    </div>

    <div class="build-billInvoicing-container" v-else-if="state.invoicingDialog.step === 2">
      <el-form ref="invoicingFormRef"
        :model="state.invoicingInfo"
        label-width="80px"
      >
        <div class="build-billInvoicing-text large">发票信息</div>
        <el-form-item label="发票类型">
          <el-radio-group v-model="state.invoicingInfo.invoiceType">
            <el-radio :label="1">电子发票</el-radio>
            <el-radio :label="2">纸质发票</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="发票种类">
          <el-radio-group v-model="state.invoicingInfo.invoiceSpecies">
            <el-radio :label="1">增值税专用发票</el-radio>
            <el-radio :label="2">增值税普通发票</el-radio>
          </el-radio-group>
          <div v-if="state.invoicingInfo.invoiceType === 2 && state.invoicingInfo.invoiceSpecies === 1">
            <i class="el-icon-warning"></i>
            <span>纸质发票-增值税专用发票，需邮寄发票联、抵扣联</span>
          </div>
        </el-form-item>
        <el-form-item label="上传发票" prop="invoiceFileUrl" :rules="rules.required">
          <upload :files.sync="state.invoicingInfo.invoiceFileUrl" :num="5" type="string" :download="false"  />
        </el-form-item>
        <el-form-item label="发票税率" prop="invoiceRate" :rules="rules.floatNumber">
          <div class="build-billInvoicing-input-container">
            <el-input 
              v-model="state.invoicingInfo.invoiceRate"
            >
              <template #append>
                %
              </template>
            </el-input>
          </div>
        </el-form-item>
        <div class="build-billInvoicing-text large" v-if="state.invoicingInfo.invoiceType === 2">邮寄信息</div>
        <el-form-item label="收件信息" v-if="state.invoicingInfo.invoiceType === 2">
          <div>
            <div>{{ address?.[1] }}
              <span class="red">{{ address?.[2] }}</span>
            </div>
            <div>{{ address?.[3] }}</div>
          </div>
        </el-form-item>
        <el-form-item label="物流单号" v-if="state.invoicingInfo.invoiceType === 2" prop="logisticNo" :rules="state.invoicingInfo.invoiceType !== 2 ? [] : rules.required">
          <div class="build-billInvoicing-input-container">
            <el-input 
              v-model="state.invoicingInfo.logisticNo"
            >
            </el-input>
          </div>
        </el-form-item>
        <el-form-item label="物流公司" v-if="state.invoicingInfo.invoiceType === 2" prop="logisticName" :rules="state.invoicingInfo.invoiceType !== 2 ? [] : rules.required">
          <el-select 
            class="build-billInvoicing-input-container" 
            v-model="state.invoicingInfo.logisticName"
            filterable
            clearable
            placeholder="请选择物流公司">
            <el-option v-for="item in state.invoicingDialog.logisticList" :key="item.logisticId" :label="item.logisticName" :value="item.logisticName"/>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button @click="submitInvoicing('draft')" v-if="state.invoicingDialog.step === 2">保存草稿</el-button>
      <el-button @click="$emit('update:visible', false)" v-if="state.invoicingDialog.step === 1">取消</el-button>
      <el-button @click="invoicingNext">{{ dialogText }}</el-button>
      <el-button type="primary" @click="submitInvoicing" v-if="state.invoicingDialog.step === 2">提交审核</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
  import { reactive, onMounted, computed, watch, ref } from 'vue'
  import scm from '@/http/nb/scm'
  import user from '@/http/nb/user'
  import http from '@/http/axios'
  import upload from '@/components/common/upload/index.vue'
  import { Message } from 'element-ui'
  import { floatNumber } from '@/utils/validate'

  const props = defineProps({
    visible: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Array,
      default() {
        return []
      }
    }
  })

  const emits = defineEmits(['update:visible', 'submit'])

  const state = reactive({
    invoicingDialog: {
      step: 1,
      visible: false,
      collapseActive1: '',
      collapseActive2: '',
      companyInfo: {},
      deliveryAddress: '',
      supplierInfo: {},
      logisticList: [],
    },
    invoicingInfo: {
      invoiceAmount: 0,
      invoiceAmountList: [],
      billingInvoiceNo: '',
      billingMonth: [],
      invoiceFileUrl: '',
      invoiceRate: '',
      invoiceSpecies: 1,
      invoiceType: 1,
      logisticName: '',
      logisticCode: '',
      logisticNo: '',
      supplierId: '',
    },
    selected: [],
  })

  const rules = {
    required: [{
      required: true, message: "该项为必填项", trigger: 'change'
    }],
    floatNumber: [floatNumber(100, 0)]
  }

  const address = computed(() => {
    const regRes = /([^\(（]*)([\(（].*[\)）])(.*)/.exec(state.invoicingDialog.deliveryAddress)
    return regRes ?? []
  })


  const invoicingFormRef = ref()

  const dialogText = computed(()=>{
    return state.invoicingDialog.step === 1 ? "下一步" : "上一步"
  })

  watch(() => props.selected, (newValue)=>{
    state.invoicingInfo.supplyBillingInvoiceId = newValue?.[0]?.id ?? ''
    state.invoicingInfo.billingInvoiceNo = newValue?.[0]?.billingInvoiceNo ?? ''
    state.invoicingInfo.billingMonth = newValue?.map((item)=> item.billingMonth)
    state.invoicingInfo.invoiceAmountList = newValue?.map((item)=> item.settlementAmount)
    state.invoicingInfo.invoiceAmount = newValue?.reduce((total, item)=> total + parseFloat(item.settlementAmount), 0)
    state.invoicingDialog.step = 1;
    state.invoicingDialog.collapseActive1 = '';
    state.invoicingDialog.collapseActive2 = '';
    if (state.invoicingInfo.supplyBillingInvoiceId) {
      getInfo()
    }
  })

  function invoicingNext() {
    if (state.invoicingDialog.step === 1) {
      return state.invoicingDialog.step++
    }
    state.invoicingDialog.step--
  }

  async function getInvoicingDialogInfo() {
    http.get(scm.billInvoicing.companyInfo).then((res)=>{
      if (res?.code === 0) {
        state.invoicingDialog.companyInfo = res.data ?? {}
      }
    })

    http.get(scm.billInvoicing.deliveryAddress).then((res)=>{
      if (res?.code === 0) {
        state.invoicingDialog.deliveryAddress = res.data ?? ''
      }
    })

    http.get(user.settle.getReviewInfoBySupplierId, {
      params: {
        supplierId: JSON.parse(localStorage.getItem('userInfo')).suppliersId,
      }
    }).then((res)=>{
      if (res?.code === 0) {
        state.invoicingDialog.supplierInfo = res.data ?? ''
        state.invoicingInfo.supplierId = res.data?.supplierId ?? ''
      }
    })

    const res = await http.get(user.commonNew.getLogisticList);
    if (res?.code === 0) {
      state.invoicingDialog.logisticList = res.data || [];
    }
  }

  async function submitInvoicing(type) {
    let url = scm.billInvoicing.saveInvoice
    if (type === 'draft') {
      url = scm.billInvoicing.saveDraft
    } else {
      await new Promise((resolve, reject)=>{
        invoicingFormRef.value?.validate((valid)=>{
          if (valid) {
            resolve()
          } else {
            reject()
          }
        })
      })
    }

    const params = {...state.invoicingInfo}
    Reflect.deleteProperty(params, 'logisticCode')
    Reflect.deleteProperty(params, 'invoiceAmount')
    Reflect.deleteProperty(params, 'invoiceAmountList')
    http.post(url, params).then((res)=>{
      if (res.code === 0) {
        Message.success('操作成功')
        emits('update:visible', false)
        emits('submit')
      }
    })
  }

  function getInfo() {
    http.get(scm.billInvoicing.invoiceDetail, {
      params: {
        supplyBillingInvoiceId: state.invoicingInfo.supplyBillingInvoiceId
      }
    }).then((res)=>{
      if (res?.code === 0) {
        state.invoicingInfo.invoiceFileUrl = res.data?.invoiceFileUrl
        state.invoicingInfo.logisticName = res.data?.logisticName
        state.invoicingInfo.invoiceSpecies = res.data?.invoiceSpecies
        state.invoicingInfo.billingMonth = res.data?.billingMonth
        state.invoicingInfo.invoiceRate = res.data?.invoiceRate
        state.invoicingInfo.invoiceType = res.data?.invoiceType
        state.invoicingInfo.logisticNo = res.data?.logisticNo
        state.invoicingInfo.invoiceAmount = res.data?.invoiceAmount
        state.invoicingInfo.invoiceAmountList = res.data?.invoiceAmountList
      }
    })
  }

  onMounted(()=>{
    getInvoicingDialogInfo()
  })
</script>

<style lang="scss" scoped>
  .build-billInvoicing-container {
    background-color: #fff;
    .build-billInvoicing-tip {
      margin-left: 68px;
    }
    .red {
      color: #f00;
      font-size: 12px;
    }
    .build-billInvoicing-input-container {
      width: 300px;
      display: inline-block;
    }
    .build-billInvoicing-text {
      &.large {
        font-size: 16px;
        font-weight: bold;
      }
      line-height: 24px;
    }
    :deep {
      .el-row {
        margin-bottom: 20px;
      }
      .el-collapse-item__header {
        height: auto;
        line-height: 24px;
        border: none;
      }
      .el-collapse-item__wrap {
        border: none;
      }
      .el-collapse {
        border: none;
      }
    }
  }
</style>