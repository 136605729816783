var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "开具发票",
        visible: _vm.visible,
        center: "",
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          return _vm.$emit("update:visible", $event)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _setup.state.invoicingDialog.step === 2
                ? _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _setup.submitInvoicing("draft")
                        },
                      },
                    },
                    [_vm._v("保存草稿")]
                  )
                : _vm._e(),
              _setup.state.invoicingDialog.step === 1
                ? _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("update:visible", false)
                        },
                      },
                    },
                    [_vm._v("取消")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _setup.invoicingNext } }, [
                _vm._v(_vm._s(_setup.dialogText)),
              ]),
              _setup.state.invoicingDialog.step === 2
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _setup.submitInvoicing },
                    },
                    [_vm._v("提交审核")]
                  )
                : _vm._e(),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _setup.state.invoicingDialog.step === 1
        ? _c(
            "div",
            { staticClass: "build-billInvoicing-container" },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c(
                      "div",
                      { staticClass: "build-billInvoicing-text large" },
                      [_vm._v("账单信息")]
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 12 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _c("i", { staticClass: "el-icon-warning" }),
                      _c("span", { staticClass: "red" }, [
                        _vm._v(
                          "请将账单月份打印在发票备注信息部分，便于平台审核"
                        ),
                      ]),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("账单月份"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _setup.state.invoicingDialog.collapseActive1,
                            callback: function ($$v) {
                              _vm.$set(
                                _setup.state.invoicingDialog,
                                "collapseActive1",
                                $$v
                              )
                            },
                            expression: "state.invoicingDialog.collapseActive1",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              attrs: {
                                title: `共${_setup.state.invoicingInfo.billingMonth.length}个月`,
                                name: "1",
                              },
                            },
                            _vm._l(
                              _setup.state.invoicingInfo.billingMonth,
                              function (item) {
                                return _c("div", [_vm._v(_vm._s(item))])
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 2, offset: 10 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("开票金额"),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-collapse",
                        {
                          model: {
                            value: _setup.state.invoicingDialog.collapseActive2,
                            callback: function ($$v) {
                              _vm.$set(
                                _setup.state.invoicingDialog,
                                "collapseActive2",
                                $$v
                              )
                            },
                            expression: "state.invoicingDialog.collapseActive2",
                          },
                        },
                        [
                          _c(
                            "el-collapse-item",
                            {
                              attrs: {
                                title: `共${_setup.state.invoicingInfo.invoiceAmount}元`,
                                name: "1",
                              },
                            },
                            _vm._l(
                              _setup.state.invoicingInfo.invoiceAmountList,
                              function (item) {
                                return _c("div", [_vm._v(_vm._s(item) + " 元")])
                              }
                            ),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c(
                      "div",
                      { staticClass: "build-billInvoicing-text large" },
                      [_vm._v("收票方信息")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("发票抬头"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingDialog.companyInfo.invoiceHeader
                        )
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 2, offset: "4" } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("电话"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(_setup.state.invoicingDialog.companyInfo.mobile)
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("税号"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingDialog.companyInfo.taxIdNumber
                        )
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 2, offset: "4" } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("开户行"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingDialog.companyInfo.openingBank
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("地址"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 13 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(_setup.state.invoicingDialog.companyInfo.address)
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 2, offset: "1" } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("银行账号"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingDialog.companyInfo.bankAccount
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c(
                      "div",
                      { staticClass: "build-billInvoicing-text large" },
                      [_vm._v("开票方信息")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("企业名称"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 13 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingDialog.supplierInfo.supplierName
                        )
                      ),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 2, offset: "1" } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("联系电话"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v(
                        _vm._s(
                          _setup.state.invoicingDialog.supplierInfo
                            .contactMobile
                        )
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 10 } }, [
                    _c(
                      "div",
                      { staticClass: "build-billInvoicing-text large" },
                      [_vm._v("推荐开票内容")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text" }, [
                      _vm._v("开票内容"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 15 } }, [
                    _c("div", { staticClass: "build-billInvoicing-text red" }, [
                      _vm._v("请参照对账单明细，数量、金额须一致"),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _setup.state.invoicingDialog.step === 2
        ? _c(
            "div",
            { staticClass: "build-billInvoicing-container" },
            [
              _c(
                "el-form",
                {
                  ref: "invoicingFormRef",
                  attrs: {
                    model: _setup.state.invoicingInfo,
                    "label-width": "80px",
                  },
                },
                [
                  _c("div", { staticClass: "build-billInvoicing-text large" }, [
                    _vm._v("发票信息"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票类型" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _setup.state.invoicingInfo.invoiceType,
                            callback: function ($$v) {
                              _vm.$set(
                                _setup.state.invoicingInfo,
                                "invoiceType",
                                $$v
                              )
                            },
                            expression: "state.invoicingInfo.invoiceType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("电子发票"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("纸质发票"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票种类" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _setup.state.invoicingInfo.invoiceSpecies,
                            callback: function ($$v) {
                              _vm.$set(
                                _setup.state.invoicingInfo,
                                "invoiceSpecies",
                                $$v
                              )
                            },
                            expression: "state.invoicingInfo.invoiceSpecies",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("增值税专用发票"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("增值税普通发票"),
                          ]),
                        ],
                        1
                      ),
                      _setup.state.invoicingInfo.invoiceType === 2 &&
                      _setup.state.invoicingInfo.invoiceSpecies === 1
                        ? _c("div", [
                            _c("i", { staticClass: "el-icon-warning" }),
                            _c("span", [
                              _vm._v(
                                "纸质发票-增值税专用发票，需邮寄发票联、抵扣联"
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "上传发票",
                        prop: "invoiceFileUrl",
                        rules: _setup.rules.required,
                      },
                    },
                    [
                      _c(_setup.upload, {
                        attrs: {
                          files: _setup.state.invoicingInfo.invoiceFileUrl,
                          num: 5,
                          type: "string",
                          download: false,
                        },
                        on: {
                          "update:files": function ($event) {
                            return _vm.$set(
                              _setup.state.invoicingInfo,
                              "invoiceFileUrl",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "发票税率",
                        prop: "invoiceRate",
                        rules: _setup.rules.floatNumber,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "build-billInvoicing-input-container" },
                        [
                          _c("el-input", {
                            scopedSlots: _vm._u([
                              {
                                key: "append",
                                fn: function () {
                                  return [_vm._v(" % ")]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _setup.state.invoicingInfo.invoiceRate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _setup.state.invoicingInfo,
                                  "invoiceRate",
                                  $$v
                                )
                              },
                              expression: "state.invoicingInfo.invoiceRate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _setup.state.invoicingInfo.invoiceType === 2
                    ? _c(
                        "div",
                        { staticClass: "build-billInvoicing-text large" },
                        [_vm._v("邮寄信息")]
                      )
                    : _vm._e(),
                  _setup.state.invoicingInfo.invoiceType === 2
                    ? _c("el-form-item", { attrs: { label: "收件信息" } }, [
                        _c("div", [
                          _c("div", [
                            _vm._v(_vm._s(_setup.address?.[1]) + " "),
                            _c("span", { staticClass: "red" }, [
                              _vm._v(_vm._s(_setup.address?.[2])),
                            ]),
                          ]),
                          _c("div", [_vm._v(_vm._s(_setup.address?.[3]))]),
                        ]),
                      ])
                    : _vm._e(),
                  _setup.state.invoicingInfo.invoiceType === 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "物流单号",
                            prop: "logisticNo",
                            rules:
                              _setup.state.invoicingInfo.invoiceType !== 2
                                ? []
                                : _setup.rules.required,
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "build-billInvoicing-input-container",
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _setup.state.invoicingInfo.logisticNo,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _setup.state.invoicingInfo,
                                      "logisticNo",
                                      $$v
                                    )
                                  },
                                  expression: "state.invoicingInfo.logisticNo",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _setup.state.invoicingInfo.invoiceType === 2
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "物流公司",
                            prop: "logisticName",
                            rules:
                              _setup.state.invoicingInfo.invoiceType !== 2
                                ? []
                                : _setup.rules.required,
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass:
                                "build-billInvoicing-input-container",
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择物流公司",
                              },
                              model: {
                                value: _setup.state.invoicingInfo.logisticName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _setup.state.invoicingInfo,
                                    "logisticName",
                                    $$v
                                  )
                                },
                                expression: "state.invoicingInfo.logisticName",
                              },
                            },
                            _vm._l(
                              _setup.state.invoicingDialog.logisticList,
                              function (item) {
                                return _c("el-option", {
                                  key: item.logisticId,
                                  attrs: {
                                    label: item.logisticName,
                                    value: item.logisticName,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }